import React, {useEffect, useRef, useState} from 'react'
import './Header.scss';
import {generateUUID} from "../utils/generateUUID";

export default function Header() {

    const socials = [{
        "link": "https://discord.uflax.eu/",
        "icon": "discord"
    }, {
        "link": "https://instagram.uflax.eu/",
        "icon": "instagram"
    }, {
        "link": "https://tiktok.uflax.eu/",
        "icon": "tiktok"
    }, {
        "link": "https://youtube.uflax.eu/",
        "icon": "youtube"
    },]

    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const handleScroll = () => setOpacity(window.scrollY > 0 ? 0 : 1);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header style={{ opacity, transition: 'opacity 0.3s ease' }}>
            <div>
                <img src="/logo512.png" alt="" className={"logo"}/>
            </div>
            <div>
                {
                    socials.map((social) => (
                        <a href={social.link} target={"_blank"} key={generateUUID()} className={"socialLink"} title={social.icon}>
                            <button className="join"><i className={"bi bi-" + social.icon}></i></button>
                        </a>
                    ))
                }
            </div>
        </header>
    )
}
