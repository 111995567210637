import React from 'react'
import './Home.scss';

export default function Home() {
    return (
        <div id={"Home"}>
            <div className={"namePresent"}>
                <h1>UFLAX</h1>
                <p>Presented by <a href="https://www.uxnetwork.eu/" target={"_blank"}>UXNetwork</a>, <a
                    href="https://www.nextlevelnation.de" target={"_blank"}>NextLevelNation </a>
                    and <a href="https://www.quantumrealms.de/" target={"_blank"}>QuantumRealms</a></p>
            </div>
            <div className={"twitchPresent"}>
                <div className={"section embed"}>
                    <iframe src="https://player.twitch.tv/?channel=einfachUflax&amp;parent=vorschau.domain.de"
                            frameBorder="0" allowTransparency="true"></iframe>
                </div>
                <div className={"section info"}>
                    <h3>Content-Creator auf der Streamingplattform <a href="https://twitch.tv/einfachUflax"
                                                                      target={"_blank"}><i
                        className={"bi bi-twitch"}></i>Twitch</a></h3>
                    <p>Auf dem Twitch-Kanal einfachUflax streamt Uflax seit 2024 mit einem klaren Fokus auf
                        Minecraft. <br/>
                        Dabei bietet Uflax eine Mischung aus kreativen und survival-basierten
                        Gameplay-Sessions, oft bereichert durch den Einsatz spannender Mods. <br/>
                        Besonders hervorzuheben ist
                        der intensive Austausch mit der Community, die in einer freundlichen und einladenden Atmosphäre
                        versammelt wird. <br/> Im Laufe der Zeit hat Uflax eine engagierte Zuschauerschaft aufgebaut,
                        die die
                        Streams regelmäßig verfolgt.</p>
                </div>
            </div>
            <div className={"partnersPresent"}>
                <h2>Meine offiziellen Partner:</h2>
                <div className={"cards"}>
                    <a href={"https://www.quantumrealms.de/"} target={"_blank"} className="card">
                        <img className="card-img-top" src="https://www.quantumrealms.de/logo512.png"
                             alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">QuantumRealms</h5>
                            <p className="card-text">QuantumRealms ist der ultimative Minecraft-Server für Citybuild und
                                Community. Entdecke die Freiheit des Bauens und gestalte mit uns die Zukunft unseres
                                Minecraft-Servers!</p>
                        </div>
                    </a>
                    <a href={"https://www.nextlevelnation.de/"} target={"_blank"} className="card">
                        <img className="card-img-top"
                             src="https://www.nextlevelnation.de/wp-content/uploads/2019/04/cropped-gross-192x192.png"
                             alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">NextLevelNation</h5>
                            <p className="card-text">Die NextLevelNation
                                ist eine Agentur für Streamer*innen. Wir sind Experten, wenn es darum geht,
                                Streamer*innen zu verbinden.</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}
